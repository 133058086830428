export default defineNuxtPlugin((nuxtApp) => {
    const toast = useToast();

    let createdToast = false;

    nuxtApp.hook("app:manifest:update", () => {
        if (createdToast) {
            return;
        }

        toast.add({
            title: "Update available",
            description: "A new version of the website is available.",
            timeout: 0,
            actions: [
                { label: "Refresh to Update", click: () => location.reload() }
            ]
        });

        createdToast = true;
    });
});
