import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import dashboard_45global from "/opt/buildhome/repo/middleware/dashboard.global.ts";
import route_45global from "/opt/buildhome/repo/middleware/route.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  dashboard_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/buildhome/repo/middleware/auth.ts"),
  permission: () => import("/opt/buildhome/repo/middleware/permission.ts")
}