import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";

interface ConfigInterface {
    wallet_cash_percentage: number;
    wallet_crypto_percentage: number;
    wallet_crypto_fee_percentage: number;
    case_battles_borrow_limit: number;
    affiliate_percentage_deposits: number;
    affiliate_percentage_games: number;
    leaderboard_enabled: boolean;
    rewards_enabled: boolean;
    wallet_withdraw_limit: number;
    case_opening_daily_enabled: boolean;
    rain_min_level: number;
    announcements: AnnouncementInterface[];
    vault_lock_times: number[];
    affiliate_amount: number;
    steam_group_id: string;
    case_opening_daily_perk_amount: number;
    case_battles_max_cases: number;
    socials: {
        [key: string]: string;
    };
    wallet_min_withdraw_crypto: number;
    christmas_theme: boolean;
    christmas_event: boolean;
    christmas_wager_amount: number;
    rolling_modes: Record<string, Record<string, Record<string, number>>>;
}

export const useConfigStore = defineStore("config", () => {
    const configurations = ref<ConfigInterface>();
    const xmas = useLocalStorage("xmas", false);
    const ws = useSocketStore()();
    const battleConfig = ref();

    const load = async () => {
        const { data, error } = await useApi<ConfigInterface>("config");

        if (error.value) {
            return;
        }

        configurations.value = data.value!;

        xmas.value = get("christmas_theme", false);

        ws.socket?.emit("case-battles:config");

        ws.socket?.on("case-battles:config", (data: any) => {
            battleConfig.value = data;
        });
    };

    function get<T> (key: keyof ConfigInterface, defaultValue: any = null) {
        if (!configurations.value) {
            return defaultValue as T;
        }
        return (configurations.value[key] as T) ?? (defaultValue as T);
    }

    return {
        configurations,
        battleConfig,

        load,
        get
    };
});
