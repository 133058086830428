import { useLocalStorage } from "@vueuse/core";
import { defu } from "defu";
import type { UseFetchOptions } from "nuxt/app";

export function useApi<T> (
    url: string | (() => string),
    options: UseFetchOptions<T> = {}
) {
    const auth = useAuthStore();
    const config = useRuntimeConfig();
    const toast = useCustomToast();
    const socket = useSocketStore()();
    const devMode = useLocalStorage("dev_mode", false);

    const formatMessage = (message: string) => {
        return (
            message.charAt(0).toUpperCase() +
            message.slice(1).toLocaleLowerCase()
        );
    };

    const defaults: UseFetchOptions<T> = {
        baseURL: config.public.apiUrl as string,

        headers: auth.isLogged ? { Authorization: `Bearer ${auth.token}` } : {},

        retry: 0,

        onResponse: ({ response }: any) => {
            if (response.status < 300) {
                if (response._data?.message) {
                    toast.add({
                        title: response._data.message,
                        color: "green"
                    });
                }
            }
        },

        onResponseError: (error: any) => {
            if (error.request.includes("auth/me")) {
                return;
            }

            if (error.response.status === 503) {
                socket.config ??= { maintenance: true };
                return;
            }

            if (error.response.status === 403) {
                toast.add({
                    title:
                        error.response?._data?.message ??
                        "You aren't allowed to do that",
                    color: "red"
                });
            }

            error.response?._data?.errors?.forEach?.((error: any) => {
                let showField = true;
                const message = formatMessage(error.message);

                if (
                    error.field == "referral_code" &&
                    error?.rule == "database.unique"
                ) {
                    showField = false;
                }

                toast.add({
                    title: error.field
                        ? showField
                            ? `${error.field}: ${message}`
                            : message
                        : message,
                    color: "red"
                });
            });

            if (
                error.response?._data?.message &&
                !error.response._data.message.includes("Maintenance")
            ) {
                toast.add({
                    title: error.response._data.message,
                    color: "red"
                });

                return error;
            }

            throw error;
        }
    };

    const params = defu(options, defaults);

    if (config.public.devMode) {
        console.log("HTTP Request", url, {
            body: params.body,
            query: params.query
        });
    }

    return useFetch(url, params);
}
