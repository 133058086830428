import { UTooltip } from "#components";
import { intervalToDuration } from "date-fns";

export const formatNumber = (number: number, precision: number = 2) =>
    Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
    }).format(number);

export const formatDate = (
    date: string,
    opts: Intl.DateTimeFormatOptions = {}
) => {
    return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        ...opts
    });
};

export const formatTime = (time: number | Date) => {
    if (time instanceof Date) {
        const interval = intervalToDuration({
            start: new Date(),
            end: time
        });

        time =
            (interval.hours ?? 0) * 60 +
            (interval.minutes ?? 0) +
            (interval.seconds ?? 0) / 60;
    }

    if (time / 60 >= 1) {
        const hours = Math.ceil(time / 60);
        return `${hours} hour` + (hours > 1 ? "s" : "");
    }

    return `${Math.ceil(time)} minute` + (Math.ceil(time) > 1 ? "s" : "");
};

export function getTeamAmount (mode: string) {
    if (mode.match(/[1-9]p/)) {
        return 1;
    }

    if (mode.match(/[1-9]vs[1-9]vs[1-9]/)) {
        return 3;
    }

    if (mode.match(/[1-9]vs[1-9]/)) {
        return 2;
    }

    if (mode.match(/[1-9]-way/)) {
        return parseInt(mode.replace("-way", ""));
    }

    return 1;
}

export function getTeamSize (mode: string) {
    if (mode.match(/[1-9]p/)) {
        return parseInt(mode.replace("p", ""));
    }

    if (mode.match(/[1-9]vs(.+)/)) {
        const parts = mode.split("vs");
        return parseInt(parts[0]);
    }

    if (mode.match(/[1-9]vs[1-9]vs[1-9]/)) {
        return 3;
    }

    if (mode.match(/[1-9]-way/)) {
        return 1;
    }

    return 1;
}

export function getPlayersByMode (mode: string) {
    const amount = getTeamAmount(mode);
    const size = getTeamSize(mode);

    return amount * size;
}

export function toFixedIfNecessary (value: number, dp: number) {
    return +value.toFixed(dp);
}

export const COINFLIP_SPRITES = {
    flipBlack: {
        src: "/sprites/coinflip/flip-black.png",
        rows: 10,
        isFlip: true
    },
    flipBlue: {
        src: "/sprites/coinflip/flip-blue.png",
        rows: 10,
        isFlip: true
    },
    noFlipBlack: {
        src: "/sprites/coinflip/noflip-black.png",
        rows: 9,
        isFlip: false
    },
    noFlipBlue: {
        src: "/sprites/coinflip/noflip-blue.png",
        rows: 9,
        isFlip: false
    }
};

export const parseEmoji = (str: string) => {
    const emojis = useEmojiStore();

    str = str.replace(/:/g, "");

    const emoji = emojis.find(str);

    if (!emoji) {
        return h("span", `:${str}:`);
    }

    return h(
        UTooltip,
        {
            text: emoji.name,
            popper: { placement: "top" },
            class: "relative inline-block"
        },
        () =>
            h("img", {
                src: emoji.path,
                alt: emoji.name,
                loading: "lazy",
                class: "h-5 w-5 translate-y-1"
            })
    );
};

export const parseChatMention = (content: string = "") => {
    if (!content) {
        return h("span", content);
    }

    const mentions = content.match(/(@[a-zA-Z0-9_]+)/g);

    if (mentions) {
        mentions.forEach(mention => {
            content = content.replace(
                mention,
                "<span class='text-base-blue'>" + mention + "</span>"
            );
        });
    }

    return h("span", { innerHTML: content, class: "align-bottom" });
};

export const parseChatMessage = (content: string = "") => {
    if (!content) {
        return h("span", content);
    }

    const nodes: any = [];

    if (content.includes(":")) {
        const parts = content.split(/(:[^:]+:)/g);
        const parsedParts = parts.map((part, index) => {
            if (index % 2 === 0) {
                return part;
            }

            return h(parseEmoji(part));
        });

        parsedParts.forEach((part: any) => {
            if (typeof part === "string") {
                nodes.push(parseChatMention(part));
            } else {
                nodes.push(part);
            }
        });
    } else {
        nodes.push(parseChatMention(content));
    }

    if (nodes.length > 0) { return h("p", nodes); }

    return h("span", content);
};

export const clamp = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max);
};

export const TX_STATUS: any = {
    pending: {
        label: "Pending",
        color: "#FFBF85"
    },
    completed: {
        label: "Completed",
        color: "#69E39A"
    },
    failed: {
        label: "Failed",
        color: "#FF7474"
    },
    canceled: {
        label: "Canceled",
        color: "#FF7474"
    }
};

export const linkToTransaction = (data: TransactionInteface) => {
    if (!data.extra_data?.currency) {
        return "#";
    }

    switch (data.extra_data?.currency?.toUpperCase()) {
    case "USDTE":
    case "ETH":
    case "USDC":
    case "USDT":
        return "https://etherscan.io/tx/" + data.extra_data.transactionId;
    case "TRX":
    case "USDTT":
    case "USDT-TRX":
        return `https://tronscan.org/#/transaction/${data.extra_data.transactionId}`;
    case "BTC":
        return `https://btcscan.org/tx/${data.extra_data.transactionId}`;
    case "BNB":
    case "BNB-BSC":
    case "USDT-BSC":
    case "USDTB":
        return `https://bscscan.com/tx/${data.extra_data.transactionId}`;
    case "LTC":
        return `https://blockexplorer.one/litecoin/mainnet/tx/${data.extra_data.transactionId}`;
    default:
        return `https://blockchain.com/explorer/transactions/${data.extra_data.currency}/${data.extra_data.transactionId}`;
    }
};

export const copyToClipboard = (
    text: string,
    str: string = "Copied to clipboard"
) => {
    const toast = useToast();

    navigator.clipboard.writeText(text);

    toast.add({
        color: "green",
        title: str
    });
};

export const getProviderLink = (provider: string, id: string) => {
    switch (provider) {
    case "steam":
        return `https://steamcommunity.com/profiles/${id}`;
    case "discord":
        return `https://discord.com/users/${id}`;
    default:
        return "#";
    }
};

export const socials = [
    {
        name: "Facebook",
        to: "facebook",
        icon: "i-ri-facebook-circle-fill"
    },
    {
        name: "Instagram",
        to: "instagram",
        icon: "i-ri-instagram-fill"
    },
    { name: "Discord", to: "discord", icon: "i-ri-discord-fill" },
    { name: "X", to: "x", icon: "i-ri-twitter-x-fill" },
    { name: "Youtube", to: "youtube", icon: "i-ri-youtube-fill" },
    { name: "Tiktok", to: "tiktok", icon: "i-ri-tiktok-fill" },
    { name: "Twitch", to: "twitch", icon: "i-ri-twitch-fill" },
    { name: "Kick", to: "kick", icon: "i-ri-kick-fill" },
    { name: "Steam", to: "steam", icon: "i-ri-steam-fill" }
];

export const fiatMethods = [
    {
        name: "Cards",
        image: "/images/deposit/visa.png", // mc & visa
        value: "cards"
    },
    {
        name: "Apple Pay",
        image: "/images/fiat-payment/apple_pay.svg",
        value: "apple_pay"
    },
    {
        name: "Google Pay",
        image: "/images/fiat-payment/google_pay.svg",
        value: "gpay"
    },
    // {
    //     name: "Zen",
    //     image: "/images/fiat-payment/zen.svg",
    //     value: "zen"
    // },
    // {
    //     name: "Blik",
    //     image: "/images/fiat-payment/blik.svg",
    //     value: "blik"
    // },
    // {
    //     name: "Bank Transfer",
    //     image: "/images/fiat-payment/bank_transfer.svg",
    //     value: "bank_transfer"
    // },
    {
        name: "PaySafeCard",
        image: "/images/fiat-payment/paysafecard.svg",
        value: "paysafecard"
    },
    // {
    //     name: "PaySafeCash",
    //     image: "/images/fiat-payment/paysafecash.svg",
    //     value: "paysafecash"
    // },
    // {
    //     name: "UnionPay",
    //     image: "/images/fiat-payment/union_pay.svg",
    //     value: "union_pay"
    // },
    {
        name: "Neosurf",
        image: "/images/fiat-payment/neosurf.svg",
        value: "neosurf"
    },
    {
        name: "WeChat Pay",
        image: "/images/fiat-payment/wechat_pay.svg",
        value: "wechat_pay"
    }
    // {
    //     name: "iDeal",
    //     image: "/images/fiat-payment/ideal.svg",
    //     value: "ideal"
    // },
    // {
    //     name: "Bancontact",
    //     image: "/images/fiat-payment/bancontact.svg",
    //     value: "bancontact"
    // },
    // {
    //     name: "Neteller",
    //     image: "/images/fiat-payment/neteller.svg",
    //     value: "neteller"
    // },
    // {
    //     name: "Sofort",
    //     image: "/images/fiat-payment/sofort.svg",
    //     value: "sofort"
    // },
    // {
    //     name: "Skrill",
    //     image: "/images/fiat-payment/skrill.svg",
    //     value: "skrill"
    // },
    // {
    //     name: "Twisto",
    //     image: "/images/fiat-payment/twisto.svg",
    //     value: "twisto"
    // },
    // {
    //     name: "Digital Currencies",
    //     image: "/images/fiat-payment/digital_currency.svg",
    //     value: "digital_currencies"
    // }
];
