<script setup lang="ts">
const canvasEl = ref<HTMLCanvasElement | null>(null);

onUnmounted(() => {
    if (canvasEl.value) {
        document.body.removeChild(canvasEl.value);
    }
});
</script>

<template>
    <div class="snow"></div>
</template>

<style lang="scss">
$d: 10; // density
$w: 900; // grid size
$s: 8s; // speed

// Generate the snow image using radial gradients
$grad: ();
@for $i from 0 to $d {
    $v: random(4) + 2;
    $a: random(5) * 0.1 + 0.5;
    $grad: $grad,
        radial-gradient(
            $v +
                px
                $v +
                px
                at
                (random($w - $v * 2) + $v) +
                px
                (random($w - $v * 2) + $v) +
                px,
            rgba(255, 255, 255, $a) 50%,
            rgba(0, 0, 0, 0)
        ) !global;
}

// The CSS
.snow,
.snow:before,
.snow:after {
    position: fixed;
    pointer-events: none;
    z-index: 0;
    top: -$w + px;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: $grad;
    background-size: $w + px $w + px;
    animation: snow $s linear infinite;
    content: "";
}
.snow:after {
    margin-left: -$w/3 + px;
    opacity: 0.4;
    animation-duration: $s * 2;
    animation-direction: reverse;
    filter: blur(3px);
}
.snow:before {
    animation-duration: $s * 3;
    animation-direction: reverse;
    margin-left: -$w/2 + px;
    opacity: 0.65;
    filter: blur(1.5px);
}
@keyframes snow {
    to {
        transform: translateY($w + px);
    }
}
</style>
