export default defineNuxtPlugin(async () => {
    const auth = useAuthStore();
    const route = useRoute();
    const socket = useSocketStore()();

    if (socket.config?.maintenance !== undefined && socket.config?.maintenance !== false) {
        if (route.path.startsWith("/auth/steam/callback")) {
            await auth.callback();
        } else if (route.path.startsWith("/auth/steam/manual-callback")) {
            await auth.callback(true);
        }
    }

    await auth.load();
});
