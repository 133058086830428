interface SeoInterface {
    title?: string | Function;
    description?: string;
    keywords?: string;
    robots?: string;
}

export const useSeo = (data: SeoInterface) => {
    const config = useRuntimeConfig();

    let title: any = data.title
        ? `${data.title} - ${config.public.appName}`
        : `${config.public.appName} - The best rust casino`;

    if (data.title instanceof Function) {
        title = data.title;
    }

    const description =
        data.description ||
        "Step into the ultimate premium Rust Unboxing & Case Battle Site, where top-tier design meets nonstop thrills — your ultimate gaming adventure starts here!";
    const keywords =
        data.keywords ||
        `${config.public.appName}, rust, casino, gambling, games, win, money, friends, play, fun, best`;

    const url = config.public.appUrl;

    return useSeoMeta({
        ...data,

        // titleTemplate: "%s - RustBattle",

        title,
        description,
        keywords,

        // Open Graph
        ogTitle: title,
        ogDescription: description,
        ogType: "website",
        ogUrl: url,
        ogImage: `${url}/images/logotype-2.png`,
        ogSiteName: config.public.appName
        // ogLocale: "en_US",
    });
};
