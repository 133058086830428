<script setup lang="ts">
import IconsWheel from "@/components/Icons/Wheel.vue";
import IconsCases from "@/components/Icons/Cases.vue";

const props = defineProps<{
    data: any;
}>();

const games = useGameStore();

const totalWagered = computed(() => {
    if (!props.data) {
        return 0;
    }
    return props.data.wagerStats.reduce((acc: number, curr: any) => {
        return curr.wager + acc;
    }, 0);
});
</script>

<template>
    <div class="flex flex-col gap-5">
        <p class="text-xl font-bold">
            Wager Stats
        </p>

        <div
            class="grid grid-cols-1 gap-3.5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5"
        >
            <div v-for="(v, k) in data.wagerStats" :key="k" class="col-span-1">
                <div
                    class="flex h-full flex-col items-center gap-5 rounded-2xl bg-primary_ p-5"
                >
                    <div class="h-10 w-10 rounded-lg">
                        <component
                            :is="games.getGame(v.game.slug)?.component"
                            class="h-full w-full"
                        />
                    </div>

                    <div class="flex flex-col items-center gap-1 text-center">
                        <div class="flex items-center gap-1 text-xs">
                            <img
                                src="/images/coins.png"
                                alt="Coins"
                                loading="lazy"
                                class="h-4 w-4"
                            >

                            <span>{{ formatNumber(v.wager / 100) }}</span>
                        </div>

                        <p class="text-xs uppercase text-textGray-1">
                            {{ v.game.name }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div
                    class="flex h-full flex-col items-center gap-2 rounded-2xl bg-gradient-to-b from-[#3C5EB8] to-[#5C8AFF] p-3 text-white"
                >
                    <div class="h-14 w-14 rounded-lg">
                        <img
                            src="/images/coins.png"
                            alt="Coins"
                            loading="lazy"
                            class="h-full w-full"
                        >
                    </div>

                    <div class="flex flex-col items-center gap-1 text-center">
                        <div class="flex items-center gap-1 text-xs">
                            <span>{{ formatNumber(totalWagered / 100) }}</span>
                        </div>

                        <p class="text-xs uppercase">
                            Total wagered
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="flex flex-col gap-5">
        <p class="text-xl font-bold">Game Stats</p>

        <div class="flex flex-col gap-3">
            <div
                class="flex items-center justify-between gap-8 rounded-xl bg-gradient-to-b from-[#2C8C52]/30 to-[#69E39A]/30 p-4"
            >
                <div class="flex items-center gap-3">
                    <IconsWheel :steps="[]" class="h-9 w-9 text-base-green" />

                    <div>
                        <p>Biggest Wheel Win</p>
                        <p class="text-xs uppercase text-base-green">
                                    {{
                                        data.biggestWheelWin
                                            ? data.biggestWheelWin.multiplier
                                            : 0
                                    }}x Multiplier
                                </p>
                    </div>
                </div>

                <div class="flex items-center gap-2">
                    <img
                        src="/images/coins.png"
                        alt="Coins"
                        loading="lazy"
                        class="h-5 w-5"
                    />

                    <span class="text-sm">{{
                        formatNumber(
                            data.biggestWheelWin
                                ? data.biggestWheelWin.payout / 100
                                : 0
                        )
                    }}</span>
                </div>
            </div>

            <div
                class="flex items-center justify-between gap-8 rounded-xl bg-gradient-to-b from-[#3C5EB8]/30 to-[#5C8AFF]/30 p-4"
            >
                <div class="flex items-center gap-3">
                    <IconsCases :steps="[]" class="h-9 w-9 text-base-blue" />

                    <div>
                        <p>Best Case Unboxing</p>
                        <p class="text-xs uppercase text-base-blue">
                                    6.43x Multiplier
                                </p>
                    </div>
                </div>

                <div
                    v-if="data.bestCaseUnboxing"
                    class="flex items-center gap-2"
                >
                    <div class="relative">
                        <img
                            :src="data.bestCaseUnboxing.steamItem.image"
                            class="relative h-10 w-10"
                            :alt="data.bestCaseUnboxing.steamItem.name"
                        />
                    </div>

                    <div class="flex flex-col">
                        <p class="text-sm">
                            {{ data.bestCaseUnboxing.steamItem.name }}
                        </p>
                        <span class="text-xs opacity-30">
                            {{ data.bestCaseUnboxing.case.name }}
                        </span>
                    </div>
                </div>

                <div class="flex items-center gap-2">
                    <img
                        src="/images/coins.png"
                        alt="Coins"
                        loading="lazy"
                        class="h-5 w-5"
                    />
                    <span class="text-sm">{{
                        formatNumber(
                            data.bestCaseUnboxing
                                ? data.bestCaseUnboxing.price / 100
                                : 0
                        )
                    }}</span>
                </div>
            </div>
        </div>
    </div> -->
</template>
