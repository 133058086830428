import { createSharedComposable, useWindowSize } from "@vueuse/core";

const _useMobile = () => {
    const isOpen = ref(false);
    const window = useWindowSize();

    const toggle = () => {
        isOpen.value = !isOpen.value;
    };

    const close = () => {
        isOpen.value = false;
    };

    const open = () => {
        isOpen.value = true;
    };

    const isMobile = computed(() => {
        return window.width.value <= 768;
    });

    return {
        isOpen,
        toggle,
        close,
        open,
        isMobile
    };
};

export const useMobile = createSharedComposable(_useMobile);
